import React from 'react';
import cTrails from './poc_Trails'
import footer from './footer'

const textInfo = (
  <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">Home</h1>
    </div>
    <h5 className="centertxt">A service for Hybrid Trusted Timestamping Protocol</h5>
    <img src="logo.png" alt="3vidence logo" className="centerimg"/>
    <button type="button" className="btn btn-primary centerbtn" onClick={()=> window.open('https://doc.3vidence.com/quickstart')}>Use it by free<h4></h4></button>
    <br/><br/>
    <blockquote className="alert alert-dismissible alert-success">
      <p/>
      <p className="mb-0">Creating tamper-proof evidence, improving integrity and timestamping.
      Using 3vidence, end-users can always verify originality, integrity and time of creation of any piece of data.</p>
      <footer className="blockquote-footer"><cite title="Source Title">3vidence team</cite></footer>
    </blockquote>
    <br/>
    <p>
  3vidence closes the gap (integrity and existence) between  data at  rest and data
  in use by developing a hybrid digital signature  from  lessons learned
  (as well as inspiration) of <b className="text-info">JWS, Wireguard, PGP, TOR,  safecurves, Telegram,
  Signal, RFC 3161 and Blockchain timestamping </b>. 3vidence uses hybrid-mode with
  <b className="text-info"> pre-quantum and post-quantum signature</b>  schemes (ed25519 and dilithium)
  to create the next generation trusted  timestamps with long term support and privacy.
  We empower apps with hybrid trusted timestamping technology on application layer.
    </p>

    <hr className="my-4"/>
    <h5 className="centertxt">Removing the breach from data at rest to data in use</h5>
    <img src="triad.png" alt="3vidence triad" className="centerimg" style={{width: "30%"}} />

    <hr className="my-4"/>
    <h3 className="centertxt">Common use cases</h3>
    <p>
      Every second a lot of data is created from photographs to documents, as end-users
      we don't have a way to verify originality, integrity and time of creation of this data,
      in some cases this is not an issue, but on some sectors like Education, Financial,
      Government, Healthcare, Legal, Insurance, Military this could be a main issue.
      <br/>
    </p>

    <p>
    <p/>
      <ul>
        <li>Intellectual property needs evidence to protect it from piracy and theft. </li>
        <li>Contracts and documents needs evidence to protect them forgery and tamper.</li>
        <li>Illegal Internet content / non-repudiation of certain content.</li>
        <li>Deleted / Edited controversial news.</li>
        <li>Slander, threats or insults on Internet (social networks) / Scams.</li>
        <li>Misuse of intellectual property: articles, photographs, plagiarism, etc.</li>
        <li>Publication of unauthorized information</li>
        <li>Simply demonstrate the existence of a specific content at a specific time.</li>
      </ul>
    </p>

    Please take a moment to check the report
    <a className="text-info" href="https://www.domo.com/learn/data-never-sleeps-8"  target="_blank"> Data Never Sleeps 8.0</a>
    <br/><br/>

    <button type="button" className="btn btn-primary centerbtn" onClick={cTrails}>PoC<h4></h4></button>
    <br/>

    <hr className="my-4"/>
    <h3 className="centertxt">Let’s increase trust</h3>
    <p className="centertxt">
      if you have an interesting project or idea in mind, do not hesitate to
      contact us and figure out how we can work together. <br/>
      We will be happy to hear about you
      <br/><br/>
      <a href="mailto:contact@3vidence.com"><button type="button" className="btn btn-primary centerbtn">Contact</button></a>
      <br/><br/>
    </p>

    {footer}

  </div>
)

export default textInfo
