import React from 'react';
import ReactDOM from 'react-dom';

import Tsr from "./verifyApi"

class VerifyAppFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {files: []};
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(files) {
    this.setState({ files: [] })
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

    render() {

      return(
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"  id="Sign">
            <h1 className="h2">V3rify</h1>
          </div>

          <div id="verifyWorkPlace">
          <div id="form-group">
            <p/>
            <label>Files input</label>
            <div>
              <Tsr
               onFilesAdded={this.onFilesAdded}
            />
          </div>
          <p/>
        </div>

          <div className="table-responsive">
            <table className="table table-striped table-sm">
              <thead>
                <tr className="table">
                  <th>#</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Time</th>
                  <th>Sign</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Branch</th>
                </tr>
              </thead>
              <tbody>

              {this.state.files.map(file => {
                return (
                  <tr key={file.no}>
                    <td >{file.no}</td>
                    <td id={file.name}>{file.name}</td>
                    <td id={"size"+file.name}>{file.sH}</td>
                    <td id={"time"+file.name}>{file.name}</td>
                    <td id={"sign"+file.name}>{file.sign}</td>
                    <td id={"date"+file.name}>{file.name}</td>
                    <td id={"sub"+file.name}>{file.sub}</td>
                    <td id={"brn"+file.name}>{file.brn}</td>
                  </tr>
                );
              })}

              </tbody>
            </table>
          </div>


          </div>
        </div>
      );
    }

}

function verifyApp() {
  ReactDOM.render(<VerifyAppFunction />, document.getElementById('workPlace'));
}

export default verifyApp
