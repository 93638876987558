import React, { Component } from "react";
import * as CryptoJS from 'crypto-js';
import axios from 'axios';

function sending (tsr){
  if(tsr.id_hjws){
    return axios.get("https://api.3vidence.com/htsp/hjws/"+tsr.id_hjws)
      .then(response => {
        var res = tsr
        var jwsSplit = res.hjws.split(".")
        var payload = JSON.parse(window.atob(jwsSplit[1]))
        return payload
      })
      .catch(err => {
        return 0
      })
  }
  else {
    return axios.post("https://api.3vidence.com/htsp/htsr", tsr)
      .then(response => {
        var res = tsr
        var jwsSplit = res.hjws.split(".")
        var payload = JSON.parse(window.atob(jwsSplit[1]))
        return payload
      })
      .catch(err => {
        return 0
      })
  }

}

function hashFiles(files){
  var reader = new FileReader();
  reader.onloadend = function () {
    var SHA
    var idSha = files.payload.alg
    if (idSha==="sha224"){
        SHA = CryptoJS.SHA224(reader.result);
    }
    else if (idSha==="sha256"){
        SHA = CryptoJS.SHA256(reader.result);
    }
    else if (idSha==="sha384"){
        SHA = CryptoJS.SHA384(reader.result);
    }
    else if (idSha==="sha512"){
        SHA = CryptoJS.SHA512(reader.result);
    }
    else {
        SHA = CryptoJS.SHA224(reader.result);
        idSha = "sha224"
    }
    var encrypted=SHA.toString();
    var timeEnd = new Date().getTime();
    var signTime = (timeEnd-files.timeStart)/1000+' sec';
    document.getElementById("time"+files.file.name).innerHTML = signTime
    if(encrypted!==files.payload.hash){
      document.getElementById("sign"+files.file.name).innerHTML = "Bad signature"
      return
    }
    document.getElementById("sign"+files.file.name).innerHTML = "OK"
    document.getElementById("date"+files.file.name).innerHTML  = new Date(files.payload.iat)
    document.getElementById("sub"+files.file.name).innerHTML  = files.payload.sub
    document.getElementById("brn"+files.file.name).innerHTML  = files.payload.brn
  }
  reader.readAsBinaryString(files.file);
}


async function hashTsrFiles(files) {
    var timeStart = new Date().getTime();
    if(files.file===undefined || files.file.size>31457280 ){
      return;
    }

    var reader = new FileReader ();
    reader.readAsText(files.sign);
    reader.onload = async function(event) {
      var contents = JSON.parse(event.target.result);
      files.payload = await sending(contents)
      if (files.payload === 0){
        var timeEnd = new Date().getTime();
        var signTime = (timeEnd-timeStart)/1000+' sec';
        document.getElementById("sign"+files.file.name).innerHTML = 'Bad signature'
        document.getElementById("date"+files.file.name).innerHTML = '-'
        document.getElementById("time"+files.file.name).innerHTML = signTime
        return
      }
      files.timeStart=timeStart
      hashFiles(files)
    };

    reader.onerror = function(event) {
      console.error("File could not be read! Code " + event.target.error.code);
    };
}

function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

function splitFiles(files){
  var fileRegex = /_hjws.json$/
  var fileSplit =[]
  for (var i=0 ; i< files.length;i++){
    if (fileRegex.test(files[i].name)){
      for (var j=0 ; j < files.length;j++){
        if(files[j].name+'_hjws.json'=== files[i].name){
          fileSplit.push({file:files[j],sign:files[i]})
        }
      }
    }
  }
  return fileSplit
}

class Tsr extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  fileListToArray(list) {
    const array = [];
    var sFiles ;
    sFiles = splitFiles(list);
    for (var i = 0; i < sFiles.length; i++) {
      //console.log(sFiles[i].file.name)
      sFiles[i].file.no = i +1;
      sFiles[i].file.sH = humanFileSize(list.item(i).size,true)
      if( sFiles[i].file.size>31457280){
        sFiles[i].file.sign = "This file is so big for your browser"
      }
      array.push(sFiles[i].file);
    }
    for (var j=0 ; j< sFiles.length;j++){
      hashTsrFiles(sFiles[j])
    }
    return array;
  }

  render() {
    return (
      <div>
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <small className="form-text text-muted">The files will NOT be uploaded to the server.</small>
      </div>
    );
  }
}

export default Tsr;
