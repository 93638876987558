import React from 'react';
import home from './home';
import faq from './faq';
import about from './about';
import error from './error';
import textInfo from './home_txt'
import sign from './sign'
import verify from './verify'
import files from './files'
import cTrails from './poc_Trails'
import ngds from './ngds'
import search from './search'

const navBar = (
  <div>
  <nav className="navbar navbar-dark bg-dark flex-md-nowrap p-0 shadow bg-primary fixed-top">
    <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="https://3vidence.com/">3vidence</a>
    <a className="text-info col-sm-3 col-md-3 mr-0" href="https://app.3vidence.com/" target="_blank">Create evidence, provide confidence.</a>
     <ul className="navbar-nav px-3">
    </ul>
  </nav>

  <div className="container-fluid">
    <div className="row">

      <nav className="col-md-2 d-none d-md-block bg-light sidebar">
        <div className="sidebar-sticky">
          <ul className="nav flex-column">

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Site Map</span>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={home} >
                  <span data-feather="file-text"></span>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://doc.3vidence.com/"  target="_blank">
                  <span data-feather="file-text"></span>
                  Getting started
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://app.3vidence.com/"  target="_blank">
                  <span data-feather="file-text"></span>
                  App
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://github.com/liesware/3vidence_cli"  target="_blank">
                  <span data-feather="file-text"></span>
                  CLI
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://doc.3vidence.com/apiguide"  target="_blank">
                  <span data-feather="file-text"></span>
                  API
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={ngds} >
                  <span data-feather="file-text"></span>
                  NGDS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={faq} >
                  <span data-feather="file-text"></span>
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={about} >
                  <span data-feather="file-text"></span>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={error} >
                  <span data-feather="file-text"></span>
                  404
                </a>
              </li>
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Sign Your Files</span>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={files} >
                  <span data-feather="file-text"></span>
                  Overview
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={sign} >
                  <span data-feather="file-text"></span>
                  Sign
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={verify}>
                  <span data-feather="file-text"></span>
                  Verify
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={search}>
                  <span data-feather="file-text"></span>
                  Search
                </a>
              </li>
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Poc</span>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="#"  onClick={cTrails} >
                  <span data-feather="file-text"></span>
                  Clinal Trails
                </a>
              </li>

            </ul>

          </ul>
        </div>
      </nav>


      <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
        <span id="workPlace">
          {textInfo}
        </span>
      </main>
    </div>
  </div>

  </div>

);

export default navBar
