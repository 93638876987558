import React from 'react';
import ReactDOM from 'react-dom';

const textInfo = (
<div className="jumbotron">
<h4>How does it work? </h4><br/>
S1GN
<ul>
 <li>Selet Sign.</li>
 <li>On hash drop down menu select the hash funtion.</li>
 <li>Select your file.</li>
 <li>Download your signature.</li>
</ul>

V3RIFY
<ul>
 <li>Selet Verify.</li>
 <li>Select your file and the signature.</li>
</ul>
<br/>

<h4>Important considerations</h4>
<ol>
 <li>Privacy policy: your data never leaves your computer, so we never have access your data.</li>
 <li>Client-side policy: the hash computation happens on your web browser.</li>
 <li>Minimal information policy: you only send us your file's fingerprint (hash).</li>
 <li>Zero data policy: you do not need to reveal or give any kind of personal information in order to use 3vidence. </li>
 <li>Open access policy: we do not limit the access. So, you can use TOR or your favorite VPN solution.</li>
 <li>Access logs policy: we keep logs only the last 4 weeks in order to improve our service.</li>
</ol>
<br/>
<br/>

<em><p className="lead">
"Extraordinary claims require extraordinary evidence."<br/>
Carl Sagan
</p></em>
</div>
)

class FilesHome extends React.Component {
render() {
  return (
      <span id="workPlace">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">3vidence</h1>
        </div>
        {textInfo}
      </span>
  )
}
}

function files() {
  ReactDOM.render(<FilesHome />, document.getElementById('workPlace'));
}

export default files
