import React from 'react';
import ReactDOM from 'react-dom';
import footer from './footer'

const textInfo = (
  <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">FAQ</h1>
    </div>
    <h1 className="h5">What is Trusted timestamp?</h1>
    ﻿Trusted timestamping  is the process of securely keeping track of the creation
    and  modification time of a document. Security here means that no one—not
    even the owner of the document—should be able to change it once it has
    been recorded provided that the timestamper's integrity is never  compromised.
    Basically to ensure the existence and integrity of digital data at  specific time.
    <br/><br/>

    <h1 className="h5">Long term support?</h1>
    It is a hard question to answer because the technology evolves so fast,
    with Ed25519 it will give in the worst case at least 5 five years of security
    and zero years for  Dilithum, but in an average case you will have between
    10-15 years of security.
    <br/><br/>

    <h1 className="h5">Why should I trust you?</h1>
    All our technology, stack and code is open source, so , it is open to scrutiny.
    <br/><br/>

    <h1 className="h5">Sectors and main issues</h1>
    Education: Students records, Intellectual property.<br/>
    Financial services: Transactions.<br/>
    Government: Citizens documents.<br/>
    Healthcare: Clinical trials, Patient records.<br/>
    E-commerce﻿: Buyers transactions.<br/>
    Legal: Digital forensics,Liability.<br/>
    Tech: Intellectual property.<br/>
    Insurance: Liability.<br/>
    Transport: Supply chain records.<br/>
    Arts: Intellectual property.<br/>
    Military: Liability.
    <br/><br/>

    <h1 className="h5">What about security?</h1>
    This quote is the best way to understand our security model:<br/>
    <em>We store your encrypted keys but you have the passwords.</em>
    <br/><br/>

    <h1 className="h5">How could I share feedback?</h1>
    contact@3vidence.com
    <br/><br/>

    {footer}
  </div>
)


class FAQ extends React.Component {
  render() {
    return(
        <div>
          {textInfo}
        </div>
      )
  }

}

function faq() {
  ReactDOM.render(<FAQ />, document.getElementById('workPlace'));
}

export default faq
