import React from 'react';
import ReactDOM from 'react-dom';
import footer from './footer'

const textInfo = (
  <div>
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 className="h2">About</h1>
  </div>

  <h5 >Mission statement</h5>
  <blockquote>
    To accelerate the adoption of  new cryptographic solutions.
  </blockquote>

  <h5 >Vision statement</h5>
  <blockquote >
    Making our technologies  international standards.
  </blockquote>

  <h5 >Value statement</h5>
  <blockquote >
  <ul>
    <li>Ubuntu -> "I am because we are" </li>
    <li>Change -> "to substitute one for another"</li>
    <li>Coherence -> "suitable connection or dependence, consistency"</li>
    <li>Meliora -> "The pursuit of something better"</li>
  </ul>
  These values statements define how we are solving the problems and this is the order to do it:
  <p/>
  <em className="text-primary">Working together, being coherent to adapt
  us to challenges in order to create something better.</em>
  </blockquote>

  <hr className="my-4"/>
  <h3>Background</h3>
  The name comes from as an analogy of word "evidence", in latin is "evidentia"
  which means "proof, distinction, vivid presentation, clearness",
  in order to understand our main motivations is necessary to understand at least:
  <ul>
    <li><a className="text-info" href="https://www.activism.net/cypherpunk/manifesto.html" target="_blank">Manifesto</a></li>
    <li><a className="text-info" href="http://groups.csail.mit.edu/mac/classes/6.805/articles/crypto/cypherpunks/cyphernomicon/CP-FAQ" target="_blank">CYPHERNOMICON</a></li>
    <li><a className="text-info" href="https://projects.csmonitor.com/cypherpunk" target="_blank">Revolution</a></li>
    <li>Empowering to humans beings to enjoy advancement and benefits of cryptography on its daily life.</li>
    <li>Avoiding the 1984 cypherpunk apocalypse.</li>
  </ul>

  <hr className="my-4"/>
  <h3>Philosophy</h3>
  <p/>
  <p class="mb-0">Make each program do one thing well. To do a new job, build afresh
  rather than complicate old programs by adding new "features". </p>
  <footer class="blockquote-footer"> - <cite title="Source Title">Unix Philosophy</cite></footer>
  <p/>
  <p class="mb-0">Keep it simple and stupid.</p>
  <footer class="blockquote-footer"> - <cite title="Source Title">Slackware Philosophy</cite></footer>
  <p/>
  <p class="mb-0">An anonymous transaction system is not a secret transaction system.
  An anonymous system empowers individuals to reveal their identity when desired and only when desired;
  this is the essence of privacy.</p>
  <footer class="blockquote-footer"> - <cite title="Source Title">Cypherpunk Philosophy</cite></footer>

  <hr className="my-4"/>
  <h3>Coherence</h3>
  Our Cryptographic Core
  <p/>
  <p>
  Coherence (ko.eˈɾen.s) performs and offloads cryptography operations  with a
  focus on interoperability, flexibility and simplicity. Coherence  gives an
  interface for modern cryptographic algorithms which is inspired  by Openssl,
  it is a REST API in order to be used by any language,
  in  other words Coherence minimizes development time and code complexity.
  Some of the algorithms offered by Coherence are AES and AES candidates,
  Sosemanuk, SHA* family, HMAC, DH, RSA, DSA, ECC, NTRU.
  </p>
  <em>This technology helps us to bring post-quantum cryptography to modern web apps.</em>
  <br/><br/>
  <button type="button" className="btn btn-primary centerbtn" onClick={()=> window.open('https://github.com/liesware/coherence')}>Get it by free<h4></h4></button>


  {footer}
  </div>
)


class About extends React.Component {
  render() {
    return(
        <div>
          {textInfo}
        </div>
      )
  }

}

function about() {
  ReactDOM.render(<About />, document.getElementById('workPlace'));
}

export default about
