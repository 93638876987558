import React from 'react';
import ReactDOM from 'react-dom';
import * as CryptoJS from 'crypto-js'

class CTrailsFunction extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this)
    this.showV = this.showV.bind(this)
    this.state = {
      display: false,
      verify: true,
      trailJs : {},
      payload: {}
    }
  }

  showV() {
    console.log("ok")
  }

  getData() {
    fetch("/data/medicaltrails.json")
    .then(response => response.text())
    .then(async (data) =>{
        this.setState({trailJs: JSON.parse(data),display: true})
        var hash = CryptoJS.SHA256(data)
        var hashex = hash.toString(CryptoJS.enc.Hex)

        var hjws = fetch("/data/medicaltrails.json.hjws")
        .then(response => response.text())
        .then((response) => {return response})

        var htsr = fetch("https://api.3vidence.com/htsp/htsr",{
          headers: {'content-type': 'application/json'},
          method: 'POST',
          body: await hjws
        })
        .then(response => response.text())
        .then(async (response) => {
          var data = JSON.parse(await hjws)
          var jwsSplit = data.hjws.split(".")
          var payload = JSON.parse(window.atob(jwsSplit[1]))
          if (payload.hash===hashex){
            payload.iat = new Date (payload.iat).toString()
            this.setState({verify: true, payload: payload})
          }
        })
      }
    )
  }

  render() {
    const {display,verify,trailJs,payload} = this.state
    let records, verification, hdata
    if (display){
      records = (
        <div>
          Date: {trailJs.date}<br/><br/>
          <h5>Patient Information</h5>
          Name: {trailJs.patientInformation.name}<br/>
          Patient id: {trailJs.patientInformation.patientId}<br/>
          Status: {trailJs.patientInformation.status}<br/>
          Phone: {trailJs.patientInformation.homePhone}<br/>
          Address: {trailJs.patientInformation.address}<br/>
          Birth date: {trailJs.patientInformation.birthDate}<br/>
          Gender: {trailJs.patientInformation.gender}<br/>
          Marital Status: {trailJs.patientInformation.maritalStatus}<br/>
          Race: {trailJs.patientInformation.race}<br/><br/>
          <h5>Problems</h5>
          {trailJs.problems[0]}<br/>
          {trailJs.problems[1]}<br/><br/>
          <h5>Medications</h5>
          {trailJs.medications[0]}<br/>
          {trailJs.medications[1]}<br/><br/>
          <h5>Review of Systems</h5>
          General: {trailJs.reviewOfSystems.general}<br/>
          Eyes: {trailJs.reviewOfSystems.eyes}<br/>
          EarNoseThroat: {trailJs.reviewOfSystems.earNoseThroat}<br/>
          Cardiovascular: {trailJs.reviewOfSystems.cardiovascular}<br/>
          Gastrointestinal: {trailJs.reviewOfSystems.gastrointestinal}<br/>
          Genitourinary: {trailJs.reviewOfSystems.genitourinary}<br/>
          Musculoskeletal: {trailJs.reviewOfSystems.musculoskeletal}<br/>
          Skin: {trailJs.reviewOfSystems.skin}<br/>
          Neurologic: {trailJs.reviewOfSystems.neurologic}<br/>
          Psychiatric: {trailJs.reviewOfSystems.psychiatric}<br/>
          Endocrine: {trailJs.reviewOfSystems.endocrine}<br/>
          HemeLymphatic: {trailJs.reviewOfSystems.hemeLymphatic}<br/>
          AllergicImmunologic: {trailJs.reviewOfSystems.allergicImmunologic}<br/><br/>
          <h5>Vital Signs</h5>
          Ht: {trailJs.vitalSigns.Ht}<br/>
          Wt:{trailJs.vitalSigns.Wt}<br/>
          T: {trailJs.vitalSigns.T}<br/>
          P: {trailJs.vitalSigns.P}<br/>
          Rhythm: {trailJs.vitalSigns.Rhythm}<br/>
          R: {trailJs.vitalSigns.R}<br/>
          BP: {trailJs.vitalSigns.BP}<br/><br/>
          <h5>Physical Exam</h5>
          General Appearance: {trailJs.physicalExam.generalAppearance}<br/>
          Eyes: {trailJs.physicalExam.Eyes}<br/>
          Ears, Nose, Mouth, Throat: {trailJs.physicalExam['Ears, Nose, Mouth, Throat']}<br/>
          Respiratory: {trailJs.physicalExam.Respiratory}<br/>
          Cardiovascular: {trailJs.physicalExam.cardiovascular}<br/>
          Skin: {trailJs.physicalExam.skin}<br/>
          Problems: {trailJs.physicalExam.problems}<br/>
          Impression: {trailJs.physicalExam.impression}<br/><br/>
          <h5>Test</h5><br/>
          <h6>{trailJs.Tests.name}</h6>
          {trailJs.Tests.date}
          <div className="table-responsive">
            <table className="table table-striped table-sm">
              <thead>
                <tr className="table">
                  <th>Name</th>
                  <th>Value</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>

              {trailJs.Tests.results.map(file => {
                return (
                  <tr>
                    <td>{file.name}</td>
                    <td>{file.value}</td>
                    <td>{file.reference}</td>
                  </tr>
                );
              })}

              </tbody>
            </table>
          </div>
          <br/><br/>
        </div>
      )
      if(verify){
        verification = (
          <div>
            <span className="badge badge-success" onClick={this.showV}>This data is verified by {payload.iss}</span><br/>
            {payload.sub}: {payload.brn}<br/>
            {payload.iat}<br/>
            <a href="/data/medicaltrails.json">Data</a>: <a href="/data/medicaltrails.json.hjws">Sign </a>
          </div>
        )
      }
      else{
        verification = <span className="badge badge-danger">Warning</span>
      }

    }
    else {
      records = <div></div>
    }

    return(
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Healthcare</h1>
          </div>
          <div>
          <h5>Clinical trials</h5>
          You are a clinical laboratory and your clients access their tests and medical exams by your web application.
          You need to proof them that their tests and medical exams were created at MONTH-DAY-YEAR and this data has not been modified since its creation (json is the dedault format).
          </div>
          <br/>

          <button type="button" className="btn btn-primary" onClick={this.getData}>Get medical trails</button>
          <div><br/>
            <div align="right">
              {verification}
            </div>
            <br/><br/>
            {records}
          </div>

        </div>
      )
  }

}

function cTrails() {
  ReactDOM.render(<CTrailsFunction />, document.getElementById('workPlace'));
}

export default cTrails
