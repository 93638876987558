import React from 'react';
import ReactDOM from 'react-dom';
import footer from './footer'

const textInfo = (
  <div>
    <h1 className="h2">NGDS</h1>

    <hr className="my-4"/>
    <h3>Next Generation Digital Signatures</h3>
    HJWS - Hybrid JSON Web Signature
    <p/>
    <div className="jumbotron">
      <pre>
      {`
        {
          "alg": "ed25519+dilithium2",
          "typ": "hjws",
          "ver": 1
        }.
        {
          "iss": "3vidence.com",
          "sub": "SUBJECT",
          "brn": "BRANCH",
          "iat": 1584591092393,
          "hash": "SHA224 HEX",
          "alg": "sha224",
          "kid": "KEY ID HEX"
        }.
        [Dilithium Signature].
        [EdDSA 25519 Signature]
      `}
      </pre>
    </div>

    <h5>Head</h5>
    <ul>
      <li>alg: cryptography suite, [pre-quantum signature]+[post-quantum signature] </li>
      <li>typ: media type</li>
      <li>ver: algorithm version</li>
    </ul>

    <h5>Payload</h5>
    <ul>
      <li>iss: The principal that issued the HJWS </li>
      <li>sub: Identifies the principal that is the subject of the HJWS</li>
      <li>brn: Indetifies the branch of subject</li>
      <li>iat: Identifies the time at which the HJWS was issued in miliseconds. </li>
      <li>hash: Indicates fingerprint file.</li>
      <li>alg: Indicates fingerprint file algorithm.</li>
      <li>kid: Indicates which key was used to secure the HJWS.</li>
    </ul>

    <h5>Dilithium Signature</h5>
    <ul>
      <li>Dilithium(base64(head)+'.'+base64(payload)) </li>
    </ul>

    <h5>EdDSA 25519 Signature</h5>
    <ul>
      <li>Ed25519(base64(head)+'.'+base64(payload)+'.'+base64(Dilithium(base64(head)+'.'+base64(payload)))) </li>
    </ul>

    <div className="card text-white bg-dark mb-3">
      <div className="card-header">HJWS Anatomy</div>
      <div class="card-body">
        <h4 class="card-title"></h4>
        {`base64(head)+'.'+`}<p/>
        {`base64(payload)+'.'+`}<p/>
        {`base64(Dilithium(base64(head)+'.'+base64(payload)))+'.'+`}<p/>
        {`base64(Ed25519(base64(head)+'.'+base64(payload)+'.'+base64(Dilithium(base64(head)+'.'+base64(payload)))))`}<p/>
      </div>
    </div>


    {footer}
  </div>
)


class NGDS extends React.Component {
  render() {
    return(
        <div>
          {textInfo}
        </div>
      )
  }

}

function ngds() {
  ReactDOM.render(<NGDS />, document.getElementById('workPlace'));
}

export default ngds
