import React, { Component } from "react";
import * as CryptoJS from 'crypto-js';
import axios from 'axios';

function sending (file){
  var tsq = {algorithm: file.idSha, hash:file.hash}
  return axios.post("https://api.3vidence.com/htsp/open/htsq", tsq )
    .then(response => {
      var list = document.getElementById("sign"+file.name);
      while (list.hasChildNodes()) {
        list.removeChild(list.firstChild);
      }
      document.getElementById("sign"+file.name).innerHTML='';
      var res = response.data
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res));
      var a = document.createElement('a');
      a.href = dataStr;
      a.download = file.name+'_hjws.json';
      a.innerHTML = 'download JSON';
      var container = document.getElementById("sign"+file.name);
      container.appendChild(a);
    })
    .catch(err => {
      document.getElementById("sign"+file.name).innerHTML = err.response.data.error;
    })
}

function hashFiles (file) {
  if(file===undefined || file.size>31457280 ){
      return;
  }

  var timeStart = new Date().getTime();
  var reader = new FileReader();
  reader.onloadend = function () {
    var SHA
    var idSha = document.getElementById("hashSelect").value
    if (idSha==="sha224"){
        SHA = CryptoJS.SHA224(reader.result);
    }
    else if (idSha==="sha256"){
        SHA = CryptoJS.SHA256(reader.result);
    }
    else if (idSha==="sha384"){
        SHA = CryptoJS.SHA384(reader.result);
    }
    else if (idSha==="sha512"){
        SHA = CryptoJS.SHA512(reader.result);
    }
    else {
        SHA = CryptoJS.SHA224(reader.result);
        idSha = "sha224"
    }
    file.idSha=idSha
    file.hash=SHA.toString();
    var timeEnd = new Date().getTime();
    file.time = (timeEnd-timeStart)/1000+' sec';
    document.getElementById("hash"+file.name).innerHTML = file.hash;
    document.getElementById("time"+file.name).innerHTML = file.time;
    sending(file)
  }

  reader.readAsBinaryString(file);
}

class Tsq extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      // const array = this.fileListToArray(files);
      var array = []
      for (var i = 0; i < files.length; i++) {
        files.item(i).no = i +1;
        files.item(i).sH = this.humanFileSize(files.item(i).size,true)
        if( files.item(i).size>31457280){
          files.item(i).hash = "This file is so big for your browser"
        }
        hashFiles(files.item(i))
        array.push(files.item(i));
      }
      this.props.onFilesAdded(array);
    }
  }

  humanFileSize(bytes, si) {
      var thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
          return bytes + ' B';
      }
      var units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      var u = -1;
      do {
          bytes /= thresh;
          ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1) + ' ' + units[u];
  }

  render() {
    return (
      <div>
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <small className="form-text text-muted">The files will NOT be uploaded to the server.</small>
      </div>
    );
  }
}

export default Tsq;
