import React from 'react';
import ReactDOM from 'react-dom';

import Tsq from "./signApi"

class SignAppFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {files: [] };
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(files) {
    this.setState({ files: [] })
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

    render() {
      const hashFunc = ["sha224", "sha256", "sha384", "sha512"];
      const shalist = hashFunc.map(hashFunc => (
            <option value={hashFunc} key={hashFunc}>{hashFunc}</option>
          ));

      return(

            <div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"  id="Sign">
                <h1 className="h2">S1gn</h1>
              </div>

              <div id="signWorkPlace">
              <div id="form-group">
                <label>Hash function</label><br/>
                <select id="hashSelect">
                  {shalist}
                </select>
                <p/>
                <label>Files input</label>
                <div>
                  <Tsq
                   onFilesAdded={this.onFilesAdded}
                />
              </div>
              <p/>
            </div>

              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr className="table">
                      <th>#</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Hash</th>
                      <th>Time</th>
                      <th>Sign</th>
                    </tr>
                  </thead>
                  <tbody>

                  {this.state.files.map(file => {
                    return (
                      <tr key={file.no}>
                        <td >{file.no}</td>
                        <td id={file.name}>{file.name}</td>
                        <td id={"size"+file.name}>{file.sH}</td>
                        <td id={"hash"+file.name}>{file.hash}</td>
                        <td id={"time"+file.name}>{file.name}</td>
                        <td id={"sign"+file.name}>{file.name}</td>
                      </tr>
                    );
                  })}

                  </tbody>
                </table>
              </div>


              </div>
            </div>



      );
    }

}

function signApp() {
  ReactDOM.render(<SignAppFunction />, document.getElementById('workPlace'));
}

export default signApp
