import React from 'react';
import ReactDOM from 'react-dom';

import SearchH from "./searchApi"

class SearchAppFunction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {files: [] };
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  onFilesAdded(files) {
    this.setState({ files: [] })
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

    render() {
      const hashFunc = ["sha224", "sha256", "sha384", "sha512"];
      const shalist = hashFunc.map(hashFunc => (
            <option value={hashFunc} key={hashFunc}>{hashFunc}</option>
          ));

      return(

            <div>
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"  id="Sign">
                <h1 className="h2">S3arch</h1>
              </div>

              <div id="signWorkPlace">
              <div id="form-group">
                <label>Hash function</label><br/>
                <select id="hashSelect">
                  {shalist}
                </select>
                <p/>
                <label>Files input</label>
                <div>
                  <SearchH
                   onFilesAdded={this.onFilesAdded}
                />
              </div>
              <p/>
            </div>


            <div className="table-responsive">
              <table className="table table-striped table-sm">
                <thead>
                  <tr className="table">
                    <th>#</th>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Time</th>
                    <th>Sign</th>
                    <th>Date</th>
                    <th>Subject</th>
                    <th>Branch</th>
                  </tr>
                </thead>
                <tbody>

                {this.state.files.map(file => {
                  return (
                    <tr key={file.no}>
                      <td >{file.no}</td>
                      <td id={file.name}>{file.name}</td>
                      <td id={"size"+file.name}>{file.sH}</td>
                      <td id={"time"+file.name}>{file.time}</td>
                      <td id={"sign"+file.name}>{file.sign}</td>
                      <td id={"iat"+file.name}>{file.iat}</td>
                      <td id={"sub"+file.name}>{file.sub}</td>
                      <td id={"brn"+file.name}>{file.brn}</td>
                    </tr>
                  );
                })}

                </tbody>
              </table>
            </div>


              </div>
            </div>



      );
    }

}

function searchApp() {
  ReactDOM.render(<SearchAppFunction />, document.getElementById('workPlace'));
}

export default searchApp
