import React, { Component } from "react";
import * as CryptoJS from 'crypto-js';
import axios from 'axios';

function sending (file){
  return axios.post("https://api.3vidence.com/htsp/hash",{hash:file.hash})
    .then(response => {
      response.data.sign[0].sign = "OK"
      return response.data.sign[0]
    })
    .catch(err => {
      return {  alg: "null",brn: "null", hash: "null",
                iat: "null",iss: "null",kid: "null",sub: "null",suite: "null",
                sign: "Resource not found"
              }
    })
}

function hashFiles (file) {
  if(file===undefined || file.size>31457280 ){
      return;
  }

  var timeStart = new Date().getTime();
  var reader = new FileReader();
  reader.onloadend = async function () {
    var SHA
    var idSha = document.getElementById("hashSelect").value
    if (idSha==="sha224"){
        SHA = CryptoJS.SHA224(reader.result);
    }
    else if (idSha==="sha256"){
        SHA = CryptoJS.SHA256(reader.result);
    }
    else if (idSha==="sha384"){
        SHA = CryptoJS.SHA384(reader.result);
    }
    else if (idSha==="sha512"){
        SHA = CryptoJS.SHA512(reader.result);
    }
    else {
        SHA = CryptoJS.SHA224(reader.result);
        idSha = "sha224"
    }
    file.idSha=idSha
    file.hash=SHA.toString();
    var timeEnd = new Date().getTime();
    var payload = await  sending(file)

    document.getElementById("time"+file.name).innerHTML = (timeEnd-timeStart)/1000+' sec';
    document.getElementById("sign"+file.name).innerHTML = payload.sign
    document.getElementById("iat"+file.name).innerHTML = new Date(payload.iat)
    document.getElementById("sub"+file.name).innerHTML = payload.sub
    document.getElementById("brn"+file.name).innerHTML = payload.brn
  }

  reader.readAsBinaryString(file);
}

function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

class SearchH extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }

  async onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      // const array = this.fileListToArray(files);
      var array = []
      for (var i = 0; i < files.length; i++) {
        files.item(i).no = i +1;
        files.item(i).sH = humanFileSize(files.item(i).size,true)
        if( files.item(i).size>31457280){
          files.item(i).hash = "This file is so big for your browser"
        }
        await hashFiles(files.item(i))
        array.push(files.item(i));
      }
      this.props.onFilesAdded(array);
    }
  }

  render() {
    return (
      <div>
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <small className="form-text text-muted">The files will NOT be uploaded to the server.</small>
      </div>
    );
  }
}

export default SearchH;
